import styles from "./ClientsPage.module.css";
import filtericon from "../../assets/clientspageimages/filtericon.png";
import chinaflag from "../../assets/clientspageimages/chinaflag.png";
import deleteicon from "../../assets/clientspageimages/deleteicon.png";
import editicon from "../../assets/clientspageimages/editicon.png";
import cat from "../../assets/clientspageimages/cat.png";
import deleteiconsmall from "../../assets/clientspageimages/deleteiconsmall.png";
import { useState } from "react";
import plusicon from "../../assets/clientspageimages/plusicon.png";
import Header from "../Header/Header";

const ClientsPage = () => {
  const [toggleAdd, setToggleAdd] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);

  return (
    <div>
      <Header />
      <main>
        <section className={styles.clients}>
          <div className={styles.contentwrapper}>
            <div className={styles.clientscontent}>
              <div className={styles.clientsleft}>
                <div className={styles.textwrapper}>
                  <span className={styles.clientstext}>Clients</span>
                </div>
                <div className={styles.buttonwrapper}>
                  <button
                    onClick={() => setToggleAdd(!toggleAdd)}
                    className={styles.clientsaddbtn}
                  >
                    Add
                  </button>
                </div>
                {toggleAdd && (
                  <div className={styles.addformcontent}>
                    <form className={styles.filterform}>
                      <div>
                        <input
                          className={`${styles.addformoption} ${styles.formml} ${styles.addformtext}`}
                          type="text"
                          name="clientsname"
                          placeholder="Client’s name"
                        />
                      </div>
                      <br />
                      <div>
                        <select
                          className={`${styles.addformoption} ${styles.formml} ${styles.addformtext}`}
                          name="clientstype"
                        >
                          <option
                            className={styles.addformtext}
                            value="clienttype"
                          >
                            Client’s type
                          </option>
                          <option
                            className={styles.addformtext}
                            value="clienttype"
                          >
                            Client’s type
                          </option>
                          <option
                            className={styles.addformtext}
                            value="clienttype"
                          >
                            Client’s type
                          </option>
                          <option
                            className={styles.addformtext}
                            value="clienttype"
                          >
                            Client’s type
                          </option>
                        </select>
                      </div>
                      <br />
                      <div>
                        <input
                          className={`${styles.addformoption} ${styles.formml} ${styles.addformtext}`}
                          type="text"
                          name="clientscountry"
                          placeholder="Client’s country"
                        />
                      </div>
                      <br />
                      <div>
                        <select
                          className={`${styles.addformoption} ${styles.formml} ${styles.addformtext}`}
                          name="clientsmark"
                        >
                          <option
                            className={styles.addformtext}
                            value="clientsmark"
                          >
                            Client’s mark
                          </option>
                          <option
                            className={styles.addformtext}
                            value="clientsmark"
                          >
                            Client’s mark
                          </option>
                          <option
                            className={styles.addformtext}
                            value="clientsmark"
                          >
                            Client’s mark
                          </option>
                          <option
                            className={styles.addformtext}
                            value="clientsmark"
                          >
                            Client’s mark
                          </option>
                        </select>
                      </div>
                      <br />
                      <div className={styles.addformwrapper}>
                        <span
                          className={`${styles.addformlisttext} ${styles.addformmt}`}
                        >
                          Persons list
                        </span>
                        <button className={styles.addformbtn}>
                          <img src={plusicon} alt="plusicon" />
                        </button>
                        <span
                          className={`${styles.addformlisttext} ${styles.addformmt}`}
                        >
                          Contracts list
                        </span>
                        <button className={styles.addformbtn}>
                          <img src={plusicon} alt="plusicon" />
                        </button>
                      </div>
                      <input
                        className={`${styles.addbtn} ${styles.addformlisttext}`}
                        type="submit"
                        value="ADD"
                      />
                    </form>
                  </div>
                )}
                <div className={styles.searchwrapper}>
                  <input className={styles.searchbar} type="text" />
                </div>
              </div>
              <div className={styles.clientsright}>
                <button
                  onClick={() => setToggleFilter(!toggleFilter)}
                  className={styles.filterbtn}
                >
                  <img src={filtericon} alt="filtericon" />
                </button>
                {toggleFilter && (
                  <div className={styles.filterformcontent}>
                    <form className={styles.filterform}>
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="country"
                        >
                          Country
                        </label>
                        <input
                          className={`${styles.formoption} ${styles.formcountryml} ${styles.formtext}`}
                          type="text"
                          name="country"
                          id="country"
                        />
                      </div>
                      <br />
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="clienttype"
                        >
                          Client type
                        </label>
                        <select
                          className={`${styles.formoption} ${styles.formtypeml} ${styles.formtext}`}
                          name="clienttype"
                          id="clienttype"
                        >
                          <option
                            className={styles.formtext}
                            value="clienttype"
                          >
                            Client type
                          </option>
                          <option
                            className={styles.formtext}
                            value="clienttype"
                          >
                            Client type
                          </option>
                          <option
                            className={styles.formtext}
                            value="clienttype"
                          >
                            Client type
                          </option>
                          <option
                            className={styles.formtext}
                            value="clienttype"
                          >
                            Client type
                          </option>
                        </select>
                      </div>
                      <br />
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="clientmark"
                        >
                          Client mark
                        </label>
                        <select
                          className={`${styles.formoption} ${styles.formmarkml} ${styles.formtext}`}
                          name="clientmark"
                          id="clientmark"
                        >
                          <option
                            className={styles.formtext}
                            value="clientmark"
                          >
                            Client mark
                          </option>
                          <option
                            className={styles.formtext}
                            value="clientmark"
                          >
                            Client mark
                          </option>
                          <option
                            className={styles.formtext}
                            value="clientmark"
                          >
                            Client mark
                          </option>
                          <option
                            className={styles.formtext}
                            value="clientmark"
                          >
                            Client mark
                          </option>
                        </select>
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="onlypersonslists"
                          id="onlypersonslists"
                        />
                        <label
                          className={`${styles.formcheckboxtext} ${styles.formtextml}`}
                          htmlFor="onlypersonslists"
                        >
                          only persons lists
                        </label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="onlycontractslists"
                          id="onlycontractslists"
                        />
                        <label
                          className={`${styles.formcheckboxtext} ${styles.formtextml}`}
                          htmlFor="onlycontractslists"
                        >
                          only contracts lists
                        </label>
                      </div>
                      <br />
                      <br />
                      <input
                        className={styles.applybtn}
                        type="submit"
                        value="APPLY"
                      />
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.accordion}>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section1"
                className={styles.accordioninput}
              />
              <label htmlFor="section1" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
                <div className={styles.clientbuttons}>
                  <span className={styles.updatestatus}>2</span>
                  <button className={`${styles.deletebtn} ${styles.mr}`}>
                    <img src={deleteicon} alt="deleteicon" />
                  </button>
                  <button className={styles.editbtn}>
                    <img src={editicon} alt="editicon" />
                  </button>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.inneraccordion}>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section33"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section33"
                      className={styles.inneraccordionlabel}
                    >
                      <label className={styles.listtext}>Persons list</label>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.personinfo}>
                        <div className={styles.persondetails}>
                          <img src={cat} alt="cat" />
                          <div className={styles.persontext}>
                            <span className={styles.personname}>
                              Person name
                            </span>
                            <span className={styles.personposition}>
                              Person’s job position
                            </span>
                          </div>
                        </div>
                        <button className={styles.deletebtnperson}>
                          <img src={deleteiconsmall} alt="deleteicon" />
                        </button>
                      </div>
                      <div className={styles.personinfo}>
                        <div className={styles.persondetails}>
                          <img src={cat} alt="cat" />
                          <div className={styles.persontext}>
                            <span className={styles.personname}>
                              Person name
                            </span>
                            <span className={styles.personposition}>
                              Person’s job position
                            </span>
                          </div>
                        </div>
                        <button className={styles.deletebtnperson}>
                          <img src={deleteiconsmall} alt="deleteicon" />
                        </button>
                      </div>
                      <div className={styles.personinfo}>
                        <div className={styles.persondetails}>
                          <img src={cat} alt="cat" />
                          <div className={styles.persontext}>
                            <span className={styles.personname}>
                              Person name
                            </span>
                            <span className={styles.personposition}>
                              Person’s job position
                            </span>
                          </div>
                        </div>
                        <button className={styles.deletebtnperson}>
                          <img src={deleteiconsmall} alt="deleteicon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section44"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section44"
                      className={styles.inneraccordionlabel}
                    >
                      <label className={styles.listtext}>Contracts list</label>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.contractinfo}>
                        <div className={styles.contractdetails}>
                          <span className={styles.contractname}>
                            Contracts name
                          </span>
                          <span className={styles.contractdate}>Date</span>
                        </div>
                        <div className={styles.contractitems}>
                          <span className={styles.status}>paid</span>
                          <button className={styles.deletebtnperson}>
                            <img src={deleteiconsmall} alt="deleteicon" />
                          </button>
                        </div>
                      </div>
                      <div className={styles.contractinfo}>
                        <div className={styles.contractdetails}>
                          <span className={styles.contractname}>
                            Contracts name
                          </span>
                          <span className={styles.contractdate}>Date</span>
                        </div>
                        <div className={styles.contractitems}>
                          <span className={styles.status}>paid</span>
                          <button className={styles.deletebtnperson}>
                            <img src={deleteiconsmall} alt="deleteicon" />
                          </button>
                        </div>
                      </div>
                      <div className={styles.contractinfo}>
                        <div className={styles.contractdetails}>
                          <span className={styles.contractname}>
                            Contracts name
                          </span>
                          <span className={styles.contractdate}>Date</span>
                        </div>
                        <div className={styles.contractitems}>
                          <span className={styles.status}>trial</span>
                          <button className={styles.deletebtnperson}>
                            <img src={deleteiconsmall} alt="deleteicon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section2"
                className={styles.accordioninput}
              />
              <label htmlFor="section2" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
                <div className={styles.clientbuttons}>
                  <span className={styles.updatestatus}>2</span>
                  <button className={`${styles.deletebtn} ${styles.mr}`}>
                    <img src={deleteicon} alt="deleteicon" />
                  </button>
                  <button className={styles.editbtn}>
                    <img src={editicon} alt="editicon" />
                  </button>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.inneraccordion}>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section55"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section55"
                      className={styles.inneraccordionlabel}
                    >
                      <label className={styles.listtext}>Persons list</label>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.personinfo}>
                        <div className={styles.persondetails}>
                          <img src={cat} alt="cat" />
                          <div className={styles.persontext}>
                            <span className={styles.personname}>
                              Person name
                            </span>
                            <span className={styles.personposition}>
                              Person’s job position
                            </span>
                          </div>
                        </div>
                        <button className={styles.deletebtnperson}>
                          <img src={deleteiconsmall} alt="deleteicon" />
                        </button>
                      </div>
                      <div className={styles.personinfo}>
                        <div className={styles.persondetails}>
                          <img src={cat} alt="cat" />
                          <div className={styles.persontext}>
                            <span className={styles.personname}>
                              Person name
                            </span>
                            <span className={styles.personposition}>
                              Person’s job position
                            </span>
                          </div>
                        </div>
                        <button className={styles.deletebtnperson}>
                          <img src={deleteiconsmall} alt="deleteicon" />
                        </button>
                      </div>
                      <div className={styles.personinfo}>
                        <div className={styles.persondetails}>
                          <img src={cat} alt="cat" />
                          <div className={styles.persontext}>
                            <span className={styles.personname}>
                              Person name
                            </span>
                            <span className={styles.personposition}>
                              Person’s job position
                            </span>
                          </div>
                        </div>
                        <button className={styles.deletebtnperson}>
                          <img src={deleteiconsmall} alt="deleteicon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section66"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section66"
                      className={styles.inneraccordionlabel}
                    >
                      <label className={styles.listtext}>Contracts list</label>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.contractinfo}>
                        <div className={styles.contractdetails}>
                          <span className={styles.contractname}>
                            Contracts name
                          </span>
                          <span className={styles.contractdate}>Date</span>
                        </div>
                        <div className={styles.contractitems}>
                          <span className={styles.status}>paid</span>
                          <button className={styles.deletebtnperson}>
                            <img src={deleteiconsmall} alt="deleteicon" />
                          </button>
                        </div>
                      </div>
                      <div className={styles.contractinfo}>
                        <div className={styles.contractdetails}>
                          <span className={styles.contractname}>
                            Contracts name
                          </span>
                          <span className={styles.contractdate}>Date</span>
                        </div>
                        <div className={styles.contractitems}>
                          <span className={styles.status}>paid</span>
                          <button className={styles.deletebtnperson}>
                            <img src={deleteiconsmall} alt="deleteicon" />
                          </button>
                        </div>
                      </div>
                      <div className={styles.contractinfo}>
                        <div className={styles.contractdetails}>
                          <span className={styles.contractname}>
                            Contracts name
                          </span>
                          <span className={styles.contractdate}>Date</span>
                        </div>
                        <div className={styles.contractitems}>
                          <span className={styles.status}>trial</span>
                          <button className={styles.deletebtnperson}>
                            <img src={deleteiconsmall} alt="deleteicon" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ClientsPage;
