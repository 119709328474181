import styles from "./Header.module.css";
import logo from "../../assets/headerimages/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className={styles.matchesheader}>
      <div className={styles.contentwrapper}>
        <div className={styles.matchesheaderwrapper}>
          <div>
            <Link to="/">
              <img className={styles.logo} src={logo} alt="logo" />
            </Link>
          </div>
          <div className={styles.buttons}>
            <Link to="/" className={styles.homebtn}>
              Home
            </Link>
            <button
              className={`${styles.menubtn} ${styles.headerml} ${styles.headermr}`}
            >
              Menu
            </button>
            <button className={styles.exitbtn}>Exit</button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
