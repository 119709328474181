import Header from "../Header/Header";
import styles from "./MatchesPage.module.css";
import filtericon from "../../assets/matchespageimages/filtericon.png";
import editicon from "../../assets/matchespageimages/editiconverysmall.png";
import { useState } from "react";

const MatchesPage = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div>
      <Header />
      <main>
        <section className={styles.matches}>
          <div className={styles.contentwrapper}>
            <div className={styles.matchescontent}>
              <div className={styles.matchesleft}>
                <div className={styles.matcheswrapper}>
                  <span className={styles.matchestext}>Matches</span>
                </div>
                <div className={styles.searchwrapper}>
                  <input className={styles.searchbar} type="text" />
                </div>
              </div>
              <div className={styles.matchesright}>
                <button
                  onClick={() => setToggle(!toggle)}
                  className={styles.filterbtn}
                >
                  <img src={filtericon} alt="filtericon" />
                </button>
                {toggle && (
                  <div className={styles.filterformcontent}>
                    <form className={styles.filterform}>
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                        >
                          Period
                        </label>
                        <input
                          className={`${styles.periodyear} ${styles.formperiodml}`}
                          type="number"
                          name="year"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="month"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="day"
                        />
                        <span className={`${styles.formtext} ${styles.dash}`}>
                          -
                        </span>
                        <input
                          className={styles.periodyear}
                          type="number"
                          name="year2"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="month2"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="day2"
                        />
                      </div>
                      <br />
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="team"
                        >
                          Team
                        </label>
                        <input
                          className={`${styles.forminput} ${styles.formteamml}`}
                          type="text"
                          name="team"
                          id="team"
                        />
                      </div>
                      <br />
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="competition"
                        >
                          Competition
                        </label>
                        <input
                          className={`${styles.forminput} ${styles.formcompml}`}
                          type="text"
                          name="competition"
                          id="competition"
                        />
                      </div>
                      <br />
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="priority"
                        >
                          Priority
                        </label>
                        <input
                          className={`${styles.forminput} ${styles.formpriorml}`}
                          type="text"
                          name="priority"
                          id="priority"
                        />
                      </div>
                      <br />
                      <input
                        className={styles.savebtn}
                        type="submit"
                        value="Save"
                      />
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className={styles.matchestable}>
          <table className={styles.infotable}>
            <thead>
              <tr>
                <th className={styles.matchesth}>№</th>
                <th className={styles.matchesth}>match id</th>
                <th className={styles.matchesth}>
                  date <br />
                  of match
                </th>
                <th className={styles.matchesth}>home team name</th>
                <th className={styles.matchesth}>away team name</th>
                <th className={styles.matchesth}>competition name</th>
                <th className={styles.matchesth}>priority coefficient</th>
                <th className={styles.matchesth}>
                  current status <br />
                  of breakdown
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>2023-02-21</td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
              <tr>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}></td>
                <td className={styles.matchestd}>
                  <button className={styles.editbtn}>
                    <img
                      className={styles.editimg}
                      src={editicon}
                      alt="editicon"
                    />
                  </button>
                </td>
                <td className={styles.matchestd}></td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default MatchesPage;
