import { useState } from "react";
import styles from "./EmployeesPage.module.css";
import filtericon from "../../assets/employeespageimages/filtericon.png";
import uparrow from "../../assets/employeespageimages/uparrow.png";
import downarrow from "../../assets/employeespageimages/downarrow.png";
import plus from "../../assets/employeespageimages/plus.png";
import reload from "../../assets/employeespageimages/reload.png";
import close from "../../assets/employeespageimages/close.png";
import Header from "../Header/Header";

const EmployeesPage = () => {
  const [toggleMessage, setToggleMessage] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);

  return (
    <div>
      <Header />
      <main>
        <section className={styles.employees}>
          <div className={styles.contentwrapper}>
            <div className={styles.employeescontent}>
              <div className={styles.employeesleft}>
                <div className={styles.textwrapper}>
                  <span className={styles.employeestext}>Employees</span>
                </div>
                <div className={styles.filterswrapper}>
                  <span className={styles.filterstext}>
                    Filter: News, from 01. 11. 2016 to 09. 03. 2023, (no),
                    Employees: 1
                  </span>
                  <div className={styles.filterscheckboxes}>
                    <div className={styles.mobilecheckboxes}>
                      <input
                        className={`${styles.formcheckbox} ${styles.filtersmr}`}
                        type="checkbox"
                        name="showlastcontracts"
                        id="showlastcontracts"
                      />
                      <label
                        className={`${styles.filterscheckboxtext} ${styles.filtersbigmr}`}
                        htmlFor="showlastcontracts"
                      >
                        show last contracts
                      </label>
                    </div>
                    <div className={styles.mobilecheckboxes}>
                      <input
                        className={`${styles.formcheckbox} ${styles.filtersmr}`}
                        type="checkbox"
                        name="showmarks"
                        id="showmarks"
                      />
                      <label
                        className={`${styles.filterscheckboxtext} ${styles.filtersbigmr}`}
                        htmlFor="showmarks"
                      >
                        show marks
                      </label>
                    </div>
                    <div className={styles.mobilecheckboxes}>
                      <input
                        className={`${styles.formcheckbox} ${styles.filtersmr}`}
                        type="checkbox"
                        name="shownumberofnewswithcomments"
                        id="shownumberofnewswithcomments"
                      />
                      <label
                        className={styles.filterscheckboxtext}
                        htmlFor="shownumberofnewswithcomments"
                      >
                        show number of news with comments
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.employeesright}>
                <button
                  onClick={() => setToggleFilter(!toggleFilter)}
                  className={styles.filterbtn}
                >
                  <img src={filtericon} alt="filtericon" />
                </button>
                {toggleFilter && (
                  <div className={styles.filterformcontent}>
                    <form className={styles.filterform}>
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="view"
                        >
                          View
                        </label>
                        <select
                          className={`${styles.formoption} ${styles.formviewml} ${styles.formtext}`}
                          name="view"
                          id="view"
                        >
                          <option className={styles.formtext} value="news">
                            News
                          </option>
                          <option className={styles.formtext} value="othernews">
                            Other News
                          </option>
                          <option className={styles.formtext} value="othernews">
                            Other News
                          </option>
                          <option className={styles.formtext} value="othernews">
                            Other News
                          </option>
                        </select>
                      </div>
                      <br />
                      <div className={styles.mobileforms}>
                        <div>
                          <label
                            className={`${styles.formtext} ${styles.formml}`}
                            htmlFor="peroid"
                          >
                            Period
                          </label>
                          <select
                            className={`${styles.formoption} ${styles.formperiodml} ${styles.formtext}`}
                            name="peroid"
                            id="peroid"
                          >
                            <option
                              className={styles.formtext}
                              value="forperiod"
                            >
                              for period
                            </option>
                            <option
                              className={styles.formtext}
                              value="forotherperiod"
                            >
                              for other period
                            </option>
                            <option
                              className={styles.formtext}
                              value="forotherperiod"
                            >
                              for other period
                            </option>
                            <option
                              className={styles.formtext}
                              value="forotherperiod"
                            >
                              for other period
                            </option>
                          </select>
                        </div>
                        <div className={styles.mobileformssec}>
                          <span
                            className={`${styles.formtext} ${styles.formdateml}`}
                          >
                            from
                          </span>
                          <input
                            className={`${styles.periodother} ${styles.formdateml}`}
                            type="number"
                            name="day"
                          />
                          <input
                            className={`${styles.periodother} ${styles.formdateml}`}
                            type="number"
                            name="month"
                          />
                          <input
                            className={`${styles.periodyear} ${styles.formdateml}`}
                            type="number"
                            name="year"
                          />
                          <span
                            className={`${styles.formtext} ${styles.formdateml}`}
                          >
                            to
                          </span>
                          <input
                            className={`${styles.periodother} ${styles.formdateml}`}
                            type="number"
                            name="day2"
                          />
                          <input
                            className={`${styles.periodother} ${styles.formdateml}`}
                            type="number"
                            name="month2"
                          />
                          <input
                            className={`${styles.periodyear} ${styles.formdateml}`}
                            type="number"
                            name="year2"
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="grouping"
                        >
                          Grouping
                        </label>
                        <select
                          className={`${styles.formoption} ${styles.formgroupingml} ${styles.formtext}`}
                          name="grouping"
                          id="grouping"
                        >
                          <option className={styles.formtext} value="day">
                            day
                          </option>
                          <option className={styles.formtext} value="nextday">
                            next day
                          </option>
                          <option className={styles.formtext} value="nextday">
                            next day
                          </option>
                          <option className={styles.formtext} value="nextday">
                            next day
                          </option>
                        </select>
                      </div>
                      <br />
                      <div
                        className={`${styles.formwrapper} ${styles.mobileformwrapper}`}
                      >
                        <div>
                          <label
                            className={`${styles.formtext} ${styles.formml}`}
                            htmlFor="results"
                          >
                            Results
                          </label>
                          <select
                            className={`${styles.formoption} ${styles.formresultsml} ${styles.formtext}`}
                            name="results"
                            id="results"
                          >
                            <option className={styles.formtext} value="no">
                              no
                            </option>
                            <option className={styles.formtext} value="yes">
                              yes
                            </option>
                            <option className={styles.formtext} value="yes">
                              yes
                            </option>
                            <option className={styles.formtext} value="yes">
                              yes
                            </option>
                          </select>
                        </div>
                        <div
                          className={`${styles.formwrapper} ${styles.mobileformwrappersec}`}
                        >
                          <input
                            className={`${styles.formcheckbox} ${styles.formml} ${styles.formtext}`}
                            type="checkbox"
                            name="withoutweekend"
                            id="withoutweekend"
                          />
                          <label
                            className={`${styles.formcheckboxtext} ${styles.formtextml}`}
                            htmlFor="withoutweekend"
                          >
                            without weekend
                          </label>
                          <input
                            className={`${styles.formcheckbox} ${styles.formml}`}
                            type="checkbox"
                            name="overall"
                            id="overall"
                          />
                          <label
                            className={`${styles.formcheckboxtext} ${styles.formtextml}`}
                            htmlFor="overall"
                          >
                            overall
                          </label>
                        </div>
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="departments"
                        >
                          Departments
                        </label>
                        <select
                          className={`${styles.formbigoption} ${styles.formdepartmentsml} ${styles.formtext}`}
                          name="departments"
                          id="departments"
                        >
                          <option className={styles.formtext} value="sales">
                            Sales
                          </option>
                          <option className={styles.formtext} value="it">
                            IT
                          </option>
                          <option className={styles.formtext} value="it">
                            IT
                          </option>
                          <option className={styles.formtext} value="it">
                            IT
                          </option>
                        </select>
                        <img
                          className={styles.formiconml}
                          src={plus}
                          alt="plus"
                        />
                        <img
                          className={styles.formiconml}
                          src={reload}
                          alt="reload"
                        />
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="sections"
                        >
                          Sections
                        </label>
                        <select
                          className={`${styles.formbigoption} ${styles.formsectionsml} ${styles.formtext}`}
                          name="sections"
                          id="sections"
                        >
                          <option className={styles.formtext} value="sales">
                            Sales
                          </option>
                          <option className={styles.formtext} value="it">
                            IT
                          </option>
                          <option className={styles.formtext} value="it">
                            IT
                          </option>
                          <option className={styles.formtext} value="it">
                            IT
                          </option>
                        </select>
                        <img
                          className={styles.formiconml}
                          src={plus}
                          alt="plus"
                        />
                        <img
                          className={styles.formiconml}
                          src={reload}
                          alt="reload"
                        />
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="careers"
                        >
                          Careers
                        </label>
                        <select
                          className={`${styles.formbigoption} ${styles.formcareersml} ${styles.formtext}`}
                          name="careers"
                          id="careers"
                        >
                          <option
                            className={styles.formtext}
                            value="productmethodologist"
                          >
                            Product Methodologist
                          </option>
                          <option
                            className={styles.formtext}
                            value="backenddeveloper"
                          >
                            Backend Developer
                          </option>
                          <option
                            className={styles.formtext}
                            value="backenddeveloper"
                          >
                            Backend Developer
                          </option>
                          <option
                            className={styles.formtext}
                            value="backenddeveloper"
                          >
                            Backend Developer
                          </option>
                        </select>
                        <img
                          className={styles.formiconml}
                          src={plus}
                          alt="plus"
                        />
                        <img
                          className={styles.formiconml}
                          src={reload}
                          alt="reload"
                        />
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="employees"
                        >
                          Employees
                        </label>
                        <select
                          className={`${styles.formbigoption} ${styles.formemployeesml} ${styles.formtext}`}
                          name="employees"
                          id="employees"
                        >
                          <option
                            className={styles.formtext}
                            value="nellikutsik"
                          >
                            Nelli Kutsik
                          </option>
                          <option
                            className={styles.formtext}
                            value="alexandersmolin"
                          >
                            Alexander Smolin
                          </option>
                          <option
                            className={styles.formtext}
                            value="alexandersmolin"
                          >
                            Alexander Smolin
                          </option>
                          <option
                            className={styles.formtext}
                            value="alexandersmolin"
                          >
                            Alexander Smolin
                          </option>
                        </select>
                        <img
                          className={styles.formiconml}
                          src={plus}
                          alt="plus"
                        />
                        <img
                          className={styles.formiconml}
                          src={reload}
                          alt="reload"
                        />
                      </div>
                      <div className={styles.hideinfo}>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml} ${styles.formtext}`}
                          type="checkbox"
                          name="hidewithoutnews"
                          id="hidewithoutnews"
                        />
                        <label
                          className={`${styles.formcheckboxtext} ${styles.formtextml}`}
                          htmlFor="hidewithoutnews"
                        >
                          hide without news
                        </label>
                      </div>
                      <div className={styles.hideinfo}>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="hideblocked"
                          id="hideblocked"
                        />
                        <label
                          className={`${styles.formcheckboxtext} ${styles.formtextml}`}
                          htmlFor="hideblocked"
                        >
                          hide blocked
                        </label>
                      </div>
                      <hr className={styles.formhr} />
                      <div className={styles.formwrapper}>
                        <label
                          className={`${styles.formtext} ${styles.formml}`}
                          htmlFor="onthepage"
                        >
                          On the page
                        </label>
                        <select
                          className={`${styles.pageoption} ${styles.formtext}`}
                          name="onthepage"
                          id="onthepage"
                        >
                          <option className={styles.formtext} value="1">
                            1
                          </option>
                          <option className={styles.formtext} value="2">
                            2
                          </option>
                          <option className={styles.formtext} value="3">
                            3
                          </option>
                          <option className={styles.formtext} value="4">
                            4
                          </option>
                        </select>
                        <img
                          className={styles.formiconml}
                          src={reload}
                          alt="reload"
                        />
                      </div>
                      <div className={styles.buttonwrapper}>
                        <button className={styles.templatebtn}>
                          Save as template
                        </button>
                        <button
                          className={`${styles.templatebtn} ${styles.formtemplatebtnml}`}
                        >
                          Open template
                        </button>
                        <input
                          className={`${styles.savebtn} ${styles.formsavebtnml}`}
                          type="submit"
                          value="SAVE"
                        />
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className={styles.employeeslist}>
          <div className={styles.empllist}>
            <div className={styles.empldates}>
              <div className={styles.emplinfo}>
                <span className={styles.emplname}>Name</span>
                <div className={styles.btnwrapper}>
                  <button
                    className={`${styles.emplsortbtn} ${styles.emplradiusl}`}
                  >
                    <img src={uparrow} alt="uparrow" />
                  </button>
                  <button
                    className={`${styles.emplsortbtn} ${styles.emplradiusr}`}
                  >
                    <img src={downarrow} alt="downarrow" />
                  </button>
                </div>
              </div>
              <span className={styles.empldate}>01/11</span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                02/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                03/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                04/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                05/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                06/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                07/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                08/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                09/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                10/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                11/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                12/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                13/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                14/11
              </span>
              <span className={`${styles.empldate} ${styles.emplmlsmall}`}>
                15/11
              </span>
            </div>
            <div className={styles.employee}>
              <div className={styles.employeeinfo}>
                <span className={styles.employeename}>Nelli Kutsik</span>
                <span className={styles.employeeposition}>
                  Product Methodologist
                </span>
              </div>
              <button
                onClick={() => setToggleMessage(!toggleMessage)}
                className={styles.employeebtn}
              >
                <span className={styles.employeemessages}>4</span>
              </button>
              {toggleMessage && (
                <div className={styles.message}>
                  <div className={styles.messagewrapper}>
                    <span className={styles.messagename}>Nelli Kutsik</span>
                    <button
                      onClick={() => setToggleMessage(!toggleMessage)}
                      className={styles.messagebtn}
                    >
                      <img src={close} alt="close" />
                    </button>
                  </div>
                  <hr className={styles.messagehr} />
                  <div className={styles.messagewrapper2}>
                    <span className={styles.messagenumber}># 239049</span>
                    <span className={styles.messagedate}>02/11/2023 00:00</span>
                  </div>
                  <span className={styles.messagesentby}>
                    Stellar Media - Administrator
                  </span>
                  <span
                    className={`${styles.messagedate} ${styles.messagetext}`}
                  >
                    TEXT
                  </span>
                  <div
                    className={`${styles.messagewrapper2} ${styles.messagemt}`}
                  >
                    <span className={styles.messagenumber}># 239049</span>
                    <span className={styles.messagedate}>02/11/2023 00:00</span>
                  </div>
                  <span className={styles.messagedate}>TEXT</span>
                </div>
              )}
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button
                className={`${styles.employeebtn} ${styles.employeeml} ${styles.employeebtngreen}`}
              >
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
              <button className={`${styles.employeebtn} ${styles.employeeml}`}>
                <span className={styles.employeemessages}>1</span>
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EmployeesPage;
