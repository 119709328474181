import styles from "./ContractsPage.module.css";
import chinaflag from "../../assets/contractspageimages/chinaflag.png";
import filtericon from "../../assets/contractspageimages/filtericon.png";
import Header from "../Header/Header";

const ContractsPage = () => {
  return (
    <div>
      <Header />
      <main>
        <section className={styles.contracts}>
          <div className={styles.contentwrapper}>
            <div className={styles.contractscontent}>
              <div className={styles.contractsleft}>
                <div className={styles.textwrapper}>
                  <span className={styles.contractstext}>Contracts</span>
                </div>
                <div className={styles.buttonwrapper}>
                  <button className={styles.contractsaddbtn}>Add</button>
                </div>
                <div className={styles.searchwrapper}>
                  <input className={styles.searchbar} type="text" />
                </div>
              </div>
              <div>
                <button className={styles.filterbtn}>
                  <img src={filtericon} alt="filtericon" />
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.accordion}>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section1"
                className={styles.accordioninput}
              />
              <label htmlFor="section1" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section2"
                className={styles.accordioninput}
              />
              <label htmlFor="section2" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
                <div className={styles.contractinfo}>
                  <div className={styles.contractdetails}>
                    <span className={styles.contractname}>Contracts name</span>
                    <span className={styles.contractdate}>Date</span>
                  </div>
                  <div>
                    <button className={styles.seecontractbtn}>
                      SEE CONTRACT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContractsPage;
