import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cls from "./LanguageSwitcher.module.css";
import { ThemeContext } from "../../App";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [openSelector, setOpenSelector] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    const setDefaultLanguageIfExist = () => {
      const language = localStorage.getItem("language");
      language && setSelectedLanguage(language);
    };

    setDefaultLanguageIfExist();
  }, []);

  const changeLanguage = (languageKey) => {
    i18n.changeLanguage(languageKey);
    localStorage.setItem("language", languageKey);
    setSelectedLanguage(languageKey);
    setOpenSelector(false);
  };

  return (
    <div
      className={`${cls.container} ${
        theme === "light" ? cls.containerLight : ""
      }`}
    >
      {!openSelector ? (
        <div
          className={cls.buttonContainer}
          onClick={() => setOpenSelector(true)}
        >
          {selectedLanguage === "en" ? (
            <>
              <div className={cls.buttonContainer}>
                <div className={cls.americanFlag}></div>
                <div
                  className={[cls.button, cls.eng].join(" ")}
                  onClick={() => changeLanguage("en")}
                >
                  <span
                    className={`${cls.span} ${
                      theme === "light" ? cls.spanLight : ""
                    }`}
                  >
                    English
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={cls.spainFlag}></div>
              <button
                className={[cls.button, cls.es].join(" ")}
                onClick={() => changeLanguage("es")}
              >
                <span
                  className={`${cls.span} ${
                    theme === "light" ? cls.spanLight : ""
                  }`}
                >
                  Español
                </span>
              </button>
            </>
          )}
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0981 14.5C10.9434 16.5 8.05662 16.5 6.90192 14.5L1.27276 4.75C0.118059 2.75 1.56143 0.25 3.87084 0.25L15.1292 0.250001C17.4386 0.250002 18.8819 2.75 17.7272 4.75L12.0981 14.5Z"
              fill="white"
            />
          </svg>
        </div>
      ) : (
        <div className={cls.languageSwitcherDropDown}>
          <div className={cls.buttonContainer}>
            <div className={cls.spainFlag}></div>
            <button
              className={[cls.button, cls.es].join(" ")}
              onClick={() => changeLanguage("es")}
            >
              <span
                className={`${cls.span} ${
                  theme === "light" ? cls.spanLight : ""
                }`}
              >
                Español
              </span>
            </button>
          </div>
          <div className={cls.buttonContainer}>
            <div className={cls.americanFlag}></div>
            <button
              className={[cls.button, cls.eng].join(" ")}
              onClick={() => changeLanguage("en")}
            >
              <span
                className={`${cls.span} ${
                  theme === "light" ? cls.spanLight : ""
                }`}
              >
                English
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
