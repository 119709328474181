import styles from "./ActivityPage.module.css";
import filtericon from "../../assets/activitypageimages/filtericon.png";
import chinaflag from "../../assets/activitypageimages/chinaflag.png";
import seal from "../../assets/activitypageimages/seal.png";
import Header from "../Header/Header";
import { useState } from "react";

const ActivityPage = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div>
      <Header />
      <main>
        <section className={styles.activity}>
          <div className={styles.contentwrapper}>
            <div className={styles.activitycontent}>
              <div className={styles.activityleft}>
                <div className={styles.textwrapper}>
                  <span className={styles.activitytext}>Activity</span>
                </div>
                <div className={styles.buttonwrapper}>
                  <button className={styles.activitybtn}>
                    new client communication
                  </button>
                </div>
                <div className={styles.buttonwrapper2}>
                  <button className={styles.activitybtn}>
                    new internal request
                  </button>
                </div>
              </div>
              <div className={styles.activityright}>
                <div className={styles.searchwrapper}>
                  <input className={styles.searchbar} type="text" />
                </div>
                <button
                  onClick={() => setToggle(!toggle)}
                  className={styles.filterbtn}
                >
                  <img src={filtericon} alt="filtericon" />
                </button>
                {toggle && (
                  <div className={styles.filterformcontent}>
                    <form>
                      <div className={styles.period}>
                        <label className={styles.formtext}>Period</label>
                        <input
                          className={styles.periodyear}
                          type="number"
                          name="year"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="month"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="day"
                        />
                        <span className={styles.formtext}>-</span>
                        <input
                          className={styles.periodyear}
                          type="number"
                          name="year2"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="month2"
                        />
                        <input
                          className={styles.periodother}
                          type="number"
                          name="day2"
                        />
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <label className={styles.formtext}>Filter by</label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="noanswers"
                          id="noanswers"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="noanswers"
                        >
                          no answers
                        </label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="noacceptedanswers"
                          id="noacceptedanswers"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="noacceptedanswers"
                        >
                          no accepted answers
                        </label>
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <label className={styles.formtext}>Sorted by</label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="newest"
                          id="newest"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="newest"
                        >
                          newest
                        </label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="recentactivity"
                          id="recentactivity"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="recentactivity"
                        >
                          recent activity
                        </label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="highestscore"
                          id="highestscore"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="highestscore"
                        >
                          highest score
                        </label>
                      </div>
                      <br />
                      <div className={styles.formwrapper}>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml} ${styles.formbigml}`}
                          type="checkbox"
                          name="mostfrequent"
                          id="mostfrequent"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="mostfrequent"
                        >
                          most frequent
                        </label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="onlyactive"
                          id="onlyactive"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="onlyactive"
                        >
                          only active
                        </label>
                        <input
                          className={`${styles.formcheckbox} ${styles.formml}`}
                          type="checkbox"
                          name="onlyinactive"
                          id="onlyinactive"
                        />
                        <label
                          className={styles.formcheckboxtext}
                          htmlFor="onlyinactive"
                        >
                          only inactive
                        </label>
                      </div>
                      <br />
                      <input
                        className={styles.savebtn}
                        type="submit"
                        value="Save"
                      />
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.accordion}>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section1"
                className={styles.accordioninput}
              />
              <label htmlFor="section1" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.inneraccordion}>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section33"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section33"
                      className={styles.inneraccordionlabel}
                    >
                      <label className={styles.listtext} htmlFor="personslist">
                        Persons list
                      </label>
                      <input
                        className={styles.listbox}
                        type="checkbox"
                        id="personslist"
                        name="personscheckbox"
                      />
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.comment}>
                        <div className={styles.commentinfo}>
                          <img src={seal} alt="seal" />
                          <span className={styles.personname}>Person name</span>
                          <span className={styles.personjob}>
                            Person’s job position
                          </span>
                        </div>
                        <div>
                          <div className={styles.commentwrapper}>
                            <span className={styles.commenttitle}>
                              Sticky Banner doesn't work properly on iOS
                            </span>
                            <span className={styles.commenttext}>
                              I'm stuck with a rendering issue on iOS devices. I
                              have a main_banner with a sticky position, when I
                              try to scroll down with an iOS device, it should
                              be overlapped by the columns and news divs, this
                              ...
                            </span>
                          </div>
                          <div className={styles.likewrapper}>
                            <div>
                              <span className={styles.commentlike}>
                                0 votes
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                0 answers
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                3 views
                              </span>
                            </div>
                            <div className={styles.rightlike}>
                              <span className={styles.commentlike}>
                                1 asked
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                20 min ago
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section44"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section44"
                      className={styles.inneraccordionlabel}
                    >
                      <label
                        className={styles.listtext}
                        htmlFor="contractslist"
                      >
                        Contracts list
                      </label>
                      <input
                        className={styles.listbox}
                        type="checkbox"
                        id="contractslist"
                        name="contractscheckbox"
                      />
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.comment}>
                        <div className={styles.commentinfo}>
                          <span className={styles.commentcontractname}>
                            Contract name
                          </span>
                          <span className={styles.commentcontractdate}>
                            Date
                          </span>
                        </div>
                        <div>
                          <div className={styles.commentwrapper}>
                            <span className={styles.commenttitle}>
                              Sticky Banner doesn't work properly on iOS
                            </span>
                            <span className={styles.commenttext}>
                              I'm stuck with a rendering issue on iOS devices. I
                              have a main_banner with a sticky position, when I
                              try to scroll down with an iOS device, it should
                              be overlapped by the columns and news divs, this
                              ...
                            </span>
                          </div>
                          <div className={styles.likewrapper}>
                            <div>
                              <span className={styles.commentlike}>
                                0 votes
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                0 answers
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                3 views
                              </span>
                            </div>
                            <div className={styles.rightlike}>
                              <span className={styles.commentlike}>
                                1 asked
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                20 min ago
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section2"
                className={styles.accordioninput}
              />
              <label htmlFor="section2" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.inneraccordion}>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section55"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section55"
                      className={styles.inneraccordionlabel}
                    >
                      <label
                        className={styles.listtext}
                        htmlFor="personslist22"
                      >
                        Persons list
                      </label>
                      <input
                        className={styles.listbox}
                        type="checkbox"
                        id="personslist22"
                        name="personscheckbox"
                      />
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.comment}>
                        <div className={styles.commentinfo}>
                          <img src={seal} alt="seal" />
                          <span className={styles.personname}>Person name</span>
                          <span className={styles.personjob}>
                            Person’s job position
                          </span>
                        </div>
                        <div>
                          <div className={styles.commentwrapper}>
                            <span className={styles.commenttitle}>
                              Sticky Banner doesn't work properly on iOS
                            </span>
                            <span className={styles.commenttext}>
                              I'm stuck with a rendering issue on iOS devices. I
                              have a main_banner with a sticky position, when I
                              try to scroll down with an iOS device, it should
                              be overlapped by the columns and news divs, this
                              ...
                            </span>
                          </div>
                          <div className={styles.likewrapper}>
                            <div>
                              <span className={styles.commentlike}>
                                0 votes
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                0 answers
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                3 views
                              </span>
                            </div>
                            <div className={styles.rightlike}>
                              <span className={styles.commentlike}>
                                1 asked
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                20 min ago
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section66"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section66"
                      className={styles.inneraccordionlabel}
                    >
                      <label
                        className={styles.listtext}
                        htmlFor="contractslist22"
                      >
                        Contracts list
                      </label>
                      <input
                        className={styles.listbox}
                        type="checkbox"
                        id="contractslist22"
                        name="contractscheckbox"
                      />
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      <div className={styles.comment}>
                        <div className={styles.commentinfo}>
                          <span className={styles.commentcontractname}>
                            Contract name
                          </span>
                          <span className={styles.commentcontractdate}>
                            Date
                          </span>
                        </div>
                        <div>
                          <div className={styles.commentwrapper}>
                            <span className={styles.commenttitle}>
                              Sticky Banner doesn't work properly on iOS
                            </span>
                            <span className={styles.commenttext}>
                              I'm stuck with a rendering issue on iOS devices. I
                              have a main_banner with a sticky position, when I
                              try to scroll down with an iOS device, it should
                              be overlapped by the columns and news divs, this
                              ...
                            </span>
                          </div>
                          <div className={styles.likewrapper}>
                            <div>
                              <span className={styles.commentlike}>
                                0 votes
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                0 answers
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                3 views
                              </span>
                            </div>
                            <div className={styles.rightlike}>
                              <span className={styles.commentlike}>
                                1 asked
                              </span>
                              <span
                                className={`${styles.commentlike} ${styles.likeml}`}
                              >
                                20 min ago
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ActivityPage;
