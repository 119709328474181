import React from "react";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import cls from "./LoginPage.module.css";
import LoginForm from "../../components/LoginForm/LoginForm";

const LoginPage = () => {
  return (
    <div className={cls.container}>
      {/* change language component */}
      <LanguageSwitcher />

      <div className={cls.loginFormContainer}>
        <div className={cls.logo} />
        <LoginForm />
      </div>
      {/* arrow lines */}
    </div>
  );
};

export default LoginPage;
