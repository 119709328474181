import styles from "./Persons.module.css";
import filtericon from "../../assets/personspageimages/filtericon.png";
import chinaflag from "../../assets/personspageimages/chinaflag.png";
import deleteicon from "../../assets/personspageimages/deleteicon.png";
import editicon from "../../assets/personspageimages/editicon.png";
import bird from "../../assets/personspageimages/bird.png";
import Header from "../Header/Header";

const PersonsPage = () => {
  return (
    <div>
      <Header />
      <main>
        <section className={styles.persons}>
          <div className={styles.contentwrapper}>
            <div className={styles.personscontent}>
              <div className={styles.personsleft}>
                <div className={styles.textwrapper}>
                  <span className={styles.personstext}>Persons</span>
                </div>
                <div className={styles.buttonwrapper}>
                  <button className={styles.personsaddbtn}>Add</button>
                </div>
                <div className={styles.searchwrapper}>
                  <input className={styles.searchbar} type="text" />
                </div>
              </div>
              <div>
                <button className={styles.filterbtn}>
                  <img src={filtericon} alt="filtericon" />
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.accordion}>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section1"
                className={styles.accordioninput}
              />
              <label htmlFor="section1" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
                <div className={styles.clientbuttons}>
                  <button className={`${styles.deletebtn} ${styles.mr}`}>
                    <img src={deleteicon} alt="deleteicon" />
                  </button>
                  <button className={styles.editbtn}>
                    <img src={editicon} alt="editicon" />
                  </button>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section2"
                className={styles.accordioninput}
              />
              <label htmlFor="section2" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
                <div className={styles.clientbuttons}>
                  <button className={`${styles.deletebtn} ${styles.mr}`}>
                    <img src={deleteicon} alt="deleteicon" />
                  </button>
                  <button className={styles.editbtn}>
                    <img src={editicon} alt="editicon" />
                  </button>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
                <div className={styles.personinfo}>
                  <div className={styles.persondetails}>
                    <img src={bird} alt="bird" />
                    <div className={styles.persontext}>
                      <span className={styles.personname}>Person name</span>
                      <span className={styles.personposition}>
                        Person’s job position
                      </span>
                    </div>
                  </div>
                  <div className={styles.personbuttons}>
                    <button
                      className={`${styles.deletebtnperson} ${styles.mrperson}`}
                    >
                      <img src={deleteicon} alt="deleteicon" />
                    </button>
                    <button className={styles.editbtnperson}>
                      <img src={editicon} alt="editicon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PersonsPage;
