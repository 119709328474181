import React from "react";
import cls from "./Input.module.css";

const Input = (props) => {
  const {
    placeholder = "",
    value,
    onChange,
    type = "text",
    style = {},
    ...otherProps
  } = props;

  return (
    <input
      {...otherProps}
      className={cls.input}
      style={style}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
    />
  );
};

export default Input;
