import React, { Suspense, createContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./components/MainPage/MainPage";
import MatchesPage from "./components/MatchesPage/MatchesPage";
import ContractsPage from "./components/ContractsPage/ContractsPage";
import LoginPage from "./components/LoginPage/LoginPage";
import "./config/i18n/i18n";
import PersonsPage from "./components/PersonsPage/PersonsPage";
import ContractsCfmPage from "./components/ContractsCfmPage/ContractsCfmPage";
import ActivityPage from "./components/ActivityPage/ActivityPage";
import EmployeesPage from "./components/EmployeesPage/EmployeesPage";
import ClientsPage from "./components/ClientsPage/ClientsPage";

export const ThemeContext = createContext(null);

const App = () => {
  const [theme, setTheme] = useState("dark");

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Router>
        <Suspense fallback="">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/matches" element={<MatchesPage />} />
            <Route path="/contracts" element={<ContractsPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/persons" element={<PersonsPage />} />
            <Route
              path="/contractsconfirmation"
              element={<ContractsCfmPage />}
            />
            <Route path="/activity" element={<ActivityPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/clients" element={<ClientsPage />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeContext.Provider>
  );
};

export default App;
