import styles from "./ContractsCfmPage.module.css";
import filtericon from "../../assets/contractscfmpageimages/filtericon.png";
import chinaflag from "../../assets/contractscfmpageimages/chinaflag.png";
import Header from "../Header/Header";

const ContractsCfmPage = () => {
  return (
    <div>
      <main>
        <Header />
        <section className={styles.contractscfm}>
          <div className={styles.contentwrapper}>
            <div className={styles.contractscfmcontent}>
              <div className={styles.contractscfmleft}>
                <div className={styles.textwrapper}>
                  <span className={styles.contractscfmtext}>
                    Contracts confirmation
                  </span>
                </div>
                <div className={styles.searchwrapper}>
                  <input className={styles.searchbar} type="text" />
                </div>
              </div>
              <div>
                <button className={styles.filterbtn}>
                  <img src={filtericon} alt="filtericon" />
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.accordion}>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section1"
                className={styles.accordioninput}
              />
              <label htmlFor="section1" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.inneraccordion}>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section33"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section33"
                      className={styles.inneraccordionlabel}
                    >
                      <div className={styles.contract}>
                        <span className={styles.contractname}>
                          Contract name
                        </span>
                        <span className={styles.date}>Date</span>
                      </div>
                      <div className={styles.contractbuttons}>
                        <button className={`${styles.btn} ${styles.approve}`}>
                          APPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.disapprove}`}
                        >
                          DISAPPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.seecontract}`}
                        >
                          SEE CONTRACT
                        </button>
                        <span className={styles.status}>PAID</span>
                      </div>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      Manager's comments
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section44"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section44"
                      className={styles.inneraccordionlabel}
                    >
                      <div className={styles.contract}>
                        <span className={styles.contractname}>
                          Contract name
                        </span>
                        <span className={styles.date}>Date</span>
                      </div>
                      <div className={styles.contractbuttons}>
                        <button className={`${styles.btn} ${styles.approve}`}>
                          APPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.disapprove}`}
                        >
                          DISAPPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.seecontract}`}
                        >
                          SEE CONTRACT
                        </button>
                        <span className={styles.status}>TRIAL</span>
                      </div>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      Manager's comments
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.accordionsection}>
              <input
                type="checkbox"
                name="accordion"
                id="section2"
                className={styles.accordioninput}
              />
              <label htmlFor="section2" className={styles.accordionlabel}>
                <div className={styles.clientinfo}>
                  <div className={styles.clientstatus}>
                    <img src={chinaflag} alt="chinaflag" />
                    <span className={styles.clientstatustext}>Active</span>
                  </div>
                  <div className={styles.clienttext}>
                    <span className={styles.clientname}>Client name</span>
                    <span className={styles.clienttype}>
                      Client type (Club, Academy, Sports company, Betting
                      company, College, Highschool, Agent, Federation, League,
                      Other)
                    </span>
                  </div>
                </div>
              </label>
              <div className={styles.accordioncontent}>
                <div className={styles.inneraccordion}>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section55"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section55"
                      className={styles.inneraccordionlabel}
                    >
                      <div className={styles.contract}>
                        <span className={styles.contractname}>
                          Contract name
                        </span>
                        <span className={styles.date}>Date</span>
                      </div>
                      <div className={styles.contractbuttons}>
                        <button className={`${styles.btn} ${styles.approve}`}>
                          APPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.disapprove}`}
                        >
                          DISAPPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.seecontract}`}
                        >
                          SEE CONTRACT
                        </button>
                        <span className={styles.status}>PAID</span>
                      </div>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      Manager's comments
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="accordion"
                      id="section66"
                      className={styles.inneraccordioninput}
                    />
                    <label
                      htmlFor="section66"
                      className={styles.inneraccordionlabel}
                    >
                      <div className={styles.contract}>
                        <span className={styles.contractname}>
                          Contract name
                        </span>
                        <span className={styles.date}>Date</span>
                      </div>
                      <div className={styles.contractbuttons}>
                        <button className={`${styles.btn} ${styles.approve}`}>
                          APPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.disapprove}`}
                        >
                          DISAPPROVE
                        </button>
                        <button
                          className={`${styles.btn} ${styles.seecontract}`}
                        >
                          SEE CONTRACT
                        </button>
                        <span className={styles.status}>TRIAL</span>
                      </div>
                    </label>
                    <div className={styles.inneraccordioncontent}>
                      Manager's comments
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContractsCfmPage;
