import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loginUser = createAsyncThunk(
  "user/login",
  async ({ rejectWithValue }) => {
    try {
      const email = document.querySelector("[name=email]").value;
      const password = document.querySelector("[name=password]").value;
      const response = await axios.post(
        `https://erp.smrtstats.com:85/api/v1/users/login/`,
        { email, password, service: 3 }
      );
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  }
);
