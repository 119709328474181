import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loupe from "../../assets/mainpageimages/loupe.png";
import downarrows from "../../assets/mainpageimages/downarrows.png";
import logo from "../../assets/mainpageimages/logo.png";
import avatar from "../../assets/mainpageimages/avatar.png";
import { Link } from "react-router-dom";
import styles from "./MainPage.module.css";
import LoginPage from "../LoginPage/LoginPage";

const MainPage = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    const token = localStorage.getItem("token");
    if (!token || token === "undefined") {
      setIsLoggedIn(false);
      return navigate("/login");
    }
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return (
      <main className={styles.maincontent}>
        <section className={styles.mainpage}>
          <div className={styles.contentwrapper}>
            <div className={styles.mainpagewrapper}>
              <div>
                <nav className={styles.mobilenav}>
                  <Link to="/clients" className={styles.clientsbtn}>
                    <span className={styles.clientstext}>Clients</span>
                    <span className={styles.clientsicon}>
                      <img
                        className={styles.clientsloupe}
                        src={loupe}
                        alt="loupe"
                      />
                    </span>
                  </Link>
                  <Link to="/matches" className={styles.matchesbtn}>
                    <span className={styles.matchestext}>Matches</span>
                  </Link>
                  <Link
                    to="/persons"
                    className={`${styles.clientsbtn} ${styles.mt}`}
                  >
                    <span className={styles.clientstext}>Persons</span>
                    <span className={styles.clientsicon}>
                      <img
                        className={styles.clientsloupe}
                        src={loupe}
                        alt="loupe"
                      />
                    </span>
                  </Link>
                  <Link to="/contracts" className={styles.contractsbtn}>
                    <span className={styles.contractstext}>Contracts</span>
                    <span className={styles.contractsnumber}>1</span>
                  </Link>
                  <Link to="/activity" className={styles.matchesbtn}>
                    <span className={styles.matchestext}>Activity</span>
                  </Link>
                  <Link to="/employees" className={styles.contractsbtn}>
                    <span className={styles.contractstext}>Employees</span>
                    <span className={styles.contractsnumber}>1</span>
                  </Link>
                  <Link
                    to="/contractsconfirmation"
                    className={styles.contractsbtn}
                  >
                    <span className={styles.contractstext}>
                      Contracts confirmation
                    </span>
                    <span className={styles.contractsnumber}>1</span>
                  </Link>
                  <Link className={styles.matchesbtn}>
                    <span className={styles.matchestext}>Settings</span>
                  </Link>
                </nav>
              </div>
              <div className={styles.dropdown}>
                <div className={styles.dropdownwrapper}>
                  <span className={styles.dropdowntext}>Notifications</span>
                  <img
                    className={styles.dropdownimg}
                    src={downarrows}
                    alt="downarrows"
                  />
                </div>
                <div className={styles.dropdowncontent}>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                  <p className={styles.contenttext}>
                    Text <span className={styles.contentdate}>20.05.2024</span>
                  </p>
                </div>
              </div>
              <div className={styles.mobilesmrtstats}>
                <img className={styles.logo} src={logo} alt="logo" />
                <div className={styles.managerinfo}>
                  <div className={styles.avatar}>
                    <img src={avatar} alt="avatar" />
                  </div>
                  <div className={styles.manager}>
                    <p className={styles.managertext}>Manager inf</p>
                  </div>
                </div>
                <button className={styles.exitbtn} onClick={logout}>
                  Exit
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  } else {
    <LoginPage />;
  }
};

export default MainPage;
